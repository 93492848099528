import React, { useState } from "react";
import "antd/dist/antd.css";
import "../../../assets/styles.scss";
import Main from "../../../layout/layout";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Row, Col, Card } from "antd";
import Masonry from "react-masonry-css";
import ImageViewer from "../../../layout/imageViewer";
import { Helmet } from "react-helmet";
import {Link, Trans,useI18next, useTranslation} from 'gatsby-plugin-react-i18next';
import SEO from "../../../layout/seo";

// markup
const Serie = ({ location, data }) => {
  const serie = data.contentfulPhotoSeries;

  const { language} = useI18next();
  const description_key = "description_" + language;

  const [viewerVisible, setViewerVisible] = useState(false);
  const [photoVisible, setPhotoVisible] = useState(false);
  const [indexPhotoVisble, setIndexPhotoVisible] = useState(0);

  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    700: 2,
    500: 1,
  };

  const toggleImageViewer = () => {
    if (viewerVisible === false) {
      document.body.classList.add("viewer-visibile");
    } else {
      document.body.classList.remove("viewer-visibile");
    }
    setViewerVisible(!viewerVisible);
  };

  const previous = () => {
    let key = indexPhotoVisble;
    if (key > 0) {
      key = indexPhotoVisble - 1;
    }
    setIndexPhotoVisible(key);
    setPhotoVisible(serie.photos[key]);
  };

  const next = () => {
    let key = indexPhotoVisble;
    if (key < serie.photos.length - 1) {
      key = indexPhotoVisble + 1;
    }
    setIndexPhotoVisible(key);
    setPhotoVisible(serie.photos[key]);
  };

  const openPhoto = (photo, key) => {
    setPhotoVisible(photo);
    setIndexPhotoVisible(key);
    toggleImageViewer();
  };

  return (
    <main>
      <SEO title={serie.name}  description={serie[description_key][description_key]}>
      </SEO>

      <Main location={location}>
        <div className="main-container">
          {viewerVisible ? (
            <ImageViewer
              close={toggleImageViewer}
              previous={indexPhotoVisble === 0 ? false : previous}
              next={indexPhotoVisble === serie.photos.length - 1 ? false : next}
              image={photoVisible}
            ></ImageViewer>
          ) : (
            <></>
          )}
          <div className="back">
            <Link to="/photos/series">← <Trans>Series</Trans></Link>
          </div>
          <div style={{ marginBottom: "2rem" }} className="title-page">
            {serie.name}
            <div className="hr"></div>
          </div>
          <Row>
            <Col xs={24} xl={18} xxl={12}>
              <div className="description"> {serie[description_key][description_key]}</div>
            </Col>
          </Row>

          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {serie.photos.map((img, key) => {
              let image = getImage(img);

              return (
                <div onClick={() => openPhoto(img, key)}>
                  <GatsbyImage
                    objectFit="cover"
                    style={{ cursor: "pointer" }}
                    image={image}
                    alt={""}
                  />
                </div>
              );
            })}
          </Masonry>
        </div>
      </Main>
    </main>
  );
};
export const data = graphql`
query langSerie ($language: String!, $id: String) {
  contentfulPhotoSeries(id: { eq: $id }) {
    name
    slug
    description_fr {
      description_fr
    }
    description_en {
      description_en
    }
    photos {
      title
      createdAt
      gatsbyImageData(
        width: 1500
        placeholder: DOMINANT_COLOR
        formats: [AUTO, AVIF, WEBP]
      )
    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
  
`;

export default Serie;
